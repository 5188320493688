export class Debug {
  static get defaults() {
    return {
      selectors: {
        container: ':root',
      },
      classes: {
        breakpoints: 'debug-breakpoints',
        verticalRhythm: 'debug-vertical-rhythm',
        boxModel: 'debug-box-model',
      },
      timeOut: 5, // In seconds
      targetString: 'debug',
    };
  }

  constructor(options = {}) {
    this.options = { ...this.constructor.defaults, ...options };
    this.sequence = '';
    this.lastPress = 0;

    this.$container = document.querySelector(this.options.selectors.container);

    if (window.location.host.match(/(localhost|0.0.0.0|.*.visionsmedialab.ch)/) !== null) {
      window.addEventListener('keydown', (e) => this.onKeyPress(e));
    }
  }

  reset() {
    this.sequence = '';
    this.lastPress = 0;

    this.$container.classList.remove(
      this.options.classes.breakpoints,
      this.options.classes.verticalRhythm,
      this.options.classes.boxModel,
    );

    return this;
  }

  onKeyPress(e) {
    // On escape we reset everything
    if (e.key === 'Escape') {
      return this.reset();
    }

    // Key pressing strike timed out
    if (Date.now() - (this.options.timeOut * 1000) > this.lastPress) {
      this.sequence = '';
    }

    // Add current key to key sequence and save last press time
    this.sequence += e.key;
    this.lastPress = Date.now();

    // If key sequence matches target string we add target classes
    if (this.sequence.toLowerCase() === this.options.targetString) {
      this.$container.classList.add(
        this.options.classes.breakpoints,
        this.options.classes.verticalRhythm,
        this.options.classes.boxModel,
      );
    }

    return this;
  }
}

export default Debug;
