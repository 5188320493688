import Flickity from 'flickity';
import Packery from 'packery';
import Rangeslider from 'rangeslider-js';
import '@/scripts/Polyfills';

import 'flickity-fade';
import 'flickity-imagesloaded';

import { Debug } from '@/scripts/Debug';

import 'flickity/dist/flickity.min.css';
import 'flickity-fade/flickity-fade.css';
import 'rangeslider-js/dist/styles.min.css';
import '@/styles/styles.scss';

window.App = new (class {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => this.onReady());
  }

  createSliders() {
    [...document.querySelectorAll('[data-slider]')].forEach(($slider) => {
      const options = JSON.parse($slider.dataset.slider || '{}');
      const slider = new Flickity($slider, options);

      $slider.classList.add('flickity-container');

      if (options.range) {
        const $range = document.createElement('input');

        $range.type = 'range';
        $slider.insertAdjacentElement('afterend', $range);
        $slider.classList.add('-range');

        Rangeslider.create($range, {
          min: 0,
          max: slider.slides.length - 1,
          value: slider.selectedIndex,
          step: 1,
          onSlide: (index) => {
            if (slider.selectedIndex !== index) {
              slider.select(index);
            }
          },
        });

        const range = $range['rangeslider-js'];

        slider.on('select', (index) => range.update({ value: index }));
        slider.on('staticClick', (e, pointer, cellElement, cellIndex) => { slider.select(cellIndex); });
      }
    });

    return this;
  }

  createPackerys() {
    [...document.querySelectorAll('[data-packery]')].forEach(
      ($packery) => new Packery($packery, {
        itemSelector: '[data-item]',
        ...JSON.parse($packery.dataset.packery || '{}'),
      }),
    );

    return this;
  }

  updateFilters() {
    [...document.querySelectorAll(['[data-value].-active'])].forEach(($value) => {
      this.onFilterClicked($value.closest('[data-filter]'), $value);
    });
  }

  onDone() {
    return this
      .createSliders()
      .createPackerys()
      .updateFilters();
  }

  onReady() {
    document.addEventListener('click', (e) => this.onClick(e));
    document.addEventListener('keyup', (e) => this.onKeyUp(e));
    window.addEventListener('load', (e) => this.onDone(e));

    this.debugger = new Debug();

    return this
      .updateFilters();
  }

  onClick(e) {
    const $menuToggle = e.target.closest('[data-menu-toggle]');
    const $menuClose = e.target.closest('[data-menu-close]');

    if ($menuToggle || $menuClose) {
      this.onMenuToggleClicked();
    }

    const $filter = e.target.closest('[data-filter]');
    const $filterValue = e.target.closest('[data-value]');

    if ($filter && $filterValue) {
      this.onFilterClicked($filter, $filterValue);
    }

    return this;
  }

  onKeyUp(e) {
    if (e.key === 'Escape') {
      this.onMenuToggleClicked(false);
    }

    return this;
  }

  onMenuToggleClicked(force) {
    const $root = document.documentElement;
    const $menuOverlay = document.querySelector('[data-menu-overlay]');

    if ($menuOverlay) {
      $menuOverlay.classList.toggle('-show', force);
      $root.classList.toggle('is-locked', force);
    }

    return this;
  }

  onFilterClicked($filter, $value) {
    const $filterable = document.querySelector(`[data-filterable="${$filter.dataset.filter}"]`);

    if ($filterable === null) {
      return this;
    }

    // Remove active state on any previously clicked filter
    [...$filter.querySelectorAll('.-active')].forEach(($el) => $el.classList.remove('-active'));

    // Set the clicked filter to active
    $value.classList.add('-active');

    // Fetch all filterable elements, loop through them and check if the clicked filter value
    // matches the element value. If not set the hidden attribute. The element’s filterable
    // value needs to be a valid JSON array.
    [...$filterable.querySelectorAll('[data-value]')].forEach((item) => {
      const $el = item;

      // Filter value is empty, the basically means we need to reset it to show everything again.
      if ($value.dataset.value === '') {
        $el.hidden = false;
      } else {
        $el.hidden = JSON.parse($el.dataset.value).indexOf($value.dataset.value) < 0;
      }
    });

    return this;
  }
})();
